import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}><MDXTag name="h3" components={components}>{`Marifel Angeles`}</MDXTag>
      <MDXTag name="p" components={components}>{`Developer, Designer, & Educator`}</MDXTag>
      <MDXTag name="p" components={components}>{`Marifel Angeles is a full stack software developer ready to offer a unique perspective with a background in UX Design, Art, and Education. She has demonstrated her creative problem solving skills and ability to work in cross-functional teams during her 8 years of teaching and curriculum development in various contexts. She is passionate about bridging Art and Education through technology.`}</MDXTag>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {
  "title": "About",
  "slug": "about",
  "cover": "./mountains.jpg"
};
      